<template>
  <div
    class="p-4 rounded border border-gray-200 sm:min-w-160 max-w-160 flex flex-col"
    :class="stipWrapperClassNames"
    data-cy="stipWrapper"
    @drop.prevent="uploadFile"
    @dragover.prevent
    @dragenter.prevent.stop="dragenter"
    @dragleave.prevent.stop="dragleave"
  >
    <loader :is-loading="isUploading" />

    <div class="flex flex-row justify-between items-center mb-4">
      <icon-base
        :icon="isCompleted ? 'round-checked' : 'round-unchecked'"
        width="24"
        height="24"
      />
      <div class="flex items-center justify-center space-x-2">
        <div
          class="rounded px-1 py-0-25 bg-white cursor-pointer"
          v-tooltip="$t('STIPS.DELETE_REQUIRED_STIP')"
          data-cy="detachStipButton"
          @click="$emit('stip:detach', stip)"
        >
          <icon-base
            icon="trashcan-outline"
            :icon-color="UTIL_COLORS.DEFAULT"
          />
        </div>
        <label
          v-if="!isCompleted"
          class="cursor-pointer px-2 py-0.5 bg-blue-100 rounded text-primary"
        >
          {{
            isDraggingOver ? $t("STIPS.DROP_TO_UPLOAD") : $t("COMMON.UPLOAD")
          }}
          <input
            :accept="acceptTypes"
            type="file"
            class="hidden"
            multiple
            @input="uploadFile"
          />
        </label>
      </div>
    </div>
    <lf-h4 class="break-all">{{ stip.name }}</lf-h4>
    <div class="flex space-x-2 items-center mt-2">
      <lf-h4>{{ $t("COMMON.ID") }}:</lf-h4>
      <copy-button :text="stip.id">{{ stip.id }}</copy-button>
    </div>
    <p v-if="stip.description" class="mt-2 break-words">
      {{ stip.description }}
    </p>
    <p v-if="stip.file_type" class="mt-2 break-words">
      {{ getStipNameByType(stip.file_type, stipCollection) }}
    </p>
    <div
      class="p-3 mt-4 border box-border rounded bg-white flex flex-row items-center justify-between hover:bg-blue-50 hover:border-blue-50"
      v-for="file in stip.files"
      :key="file.id"
    >
      <span class="hover:text-primary text-ellipsis overflow-hidden w-40">
        {{ file.original_name }}
      </span>
      <div class="flex flex-row space-x-2">
        <button
          v-if="(!isFunder || isClient) && canEditDealProgress"
          @click.prevent="fileToDelete = file"
        >
          <icon-base icon="delete" class="text-disabled-medium" />
        </button>
        <div
          class="cursor-pointer"
          @click="downloadFile(file.file_url, {}, file.original_name)"
        >
          <icon-base icon="download" />
        </div>
      </div>
    </div>
  </div>
  <confirm-modal
    v-if="(!isFunder || isClient) && fileToDelete"
    :title="$t('STIPS.DELETE_FILE')"
    :description="
      $t('STIPS.DELETE_FILE_BODY', {
        fileName: fileToDelete.original_name,
        stipName: stip.name
      })
    "
    :confirm-button="$t('COMMON.DELETE')"
    :close="() => (fileToDelete = null)"
    confirm-button-type="error"
    @confirm="deleteFile(fileToDelete)"
  />
</template>
<script setup lang="ts">
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import type { PropType } from "vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { areFilesExtensionsValid } from "@/helpers/files";
import {
  STIPS_ALLOWED_FILE_TYPES,
  STIP_TYPE,
  UTIL_COLORS
} from "@/helpers/constants";
import { getStipNameByType } from "@/helpers/common";
import { downloadFile } from "@/helpers/files";
import type { IFile } from "@/models/common";
import type { IStip } from "@/models/applications";
import { useAuth } from "@/hooks/auth";
import { useDeals } from "@/hooks/deals";
import { useStipsCollection } from "@/hooks/stips";
import CopyButton from "@/components/ui/buttons/CopyButton.vue";
import { usePromiseWrapper } from "@/hooks/common";

type FilePayload = {
  file: File;
  file_type?: string;
  offer_stip_id?: number;
  application_stip_id?: number;
};

defineEmits<{ "stip:detach": [stip: IStip] }>();

const props = defineProps({
  stip: {
    type: Object as PropType<IStip>,
    required: true
  },
  stipType: {
    type: Number as PropType<(typeof STIP_TYPE)[keyof typeof STIP_TYPE]>,
    default: STIP_TYPE.OFFER
  }
});

const acceptTypes = STIPS_ALLOWED_FILE_TYPES.map((type) => `.${type}`).join(
  ", "
);

const { isFunder, isClient } = useAuth();
const stipCollection = useStipsCollection();
const { activeDeal, canEditDealProgress } = useDeals();
const { commit, dispatch } = useStore();
const isDraggingOver = ref(false);
const enterTarget = ref<EventTarget | null>(null);
const fileToDelete = ref<IFile | null>(null);

const isCompleted = computed(() => props.stip.files?.length);

const stipWrapperClassNames = computed(() => {
  if (isDraggingOver.value) {
    return "bg-blue-50 border-blue-200 border-dashed";
  }
  if (isCompleted.value) {
    return "border-green-200 bg-green-500 bg-opacity-5";
  }

  return "hover:bg-blue-50 hover:border-blue-200 hover:border-dashed";
});

const dragenter = (event: Event) => {
  enterTarget.value = event.target;
  isDraggingOver.value = true;
};

const dragleave = (event: Event) => {
  if (enterTarget.value === event.target) {
    isDraggingOver.value = false;
  }
};

const deleteFile = async (file: IFile | null) => {
  if (!file) {
    return;
  }
  await dispatch("applications/deleteFile", { id: file.id });
  const removePayload = {
    stip_id: props.stip.id,
    file_id: file.id
  };
  const mutation =
    props.stipType === STIP_TYPE.OFFER
      ? "applications/removeFileFromStip"
      : "applications/removeStipFileFromDeal";

  commit(mutation, removePayload);
};

const { fetchWrapper: uploadFile, loading: isUploading } = usePromiseWrapper(
  async (event: Event) => {
    let uploadedFiles = (event.target as HTMLInputElement).files;
    if (event.type === "drop") {
      enterTarget.value = null;
      isDraggingOver.value = false;

      if (
        !areFilesExtensionsValid(
          Object.values((event as DragEvent).dataTransfer?.files || {}),
          STIPS_ALLOWED_FILE_TYPES
        )
      ) {
        return;
      }
      uploadedFiles = (event as DragEvent).dataTransfer?.files || null;
    }

    if (!uploadedFiles) {
      return;
    }
    const promises: Promise<IFile>[] = Object.values(uploadedFiles).map(
      async (file) => {
        const payload: FilePayload = { file };
        if (props.stipType === STIP_TYPE.OFFER) {
          payload.file_type = "offer_stip";
          payload.offer_stip_id = props.stip.id;
        } else {
          payload.file_type = "application_stip";
          payload.application_stip_id = props.stip.id;
        }
        return dispatch("applications/uploadFile", payload);
      }
    );

    await Promise.all(promises);
    dispatch("applications/getOffers", activeDeal.value.id);
  }
);
</script>
